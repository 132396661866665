<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
   EM CONSTRUÇÃO
     <!-- <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mx-auto mt-0 ml-0 pa-1">
            
                        
           <v-col offset="0" cols="12" xl="2" lg="4" md="4" sm="4" xs="6">
             <v-text-field
                transition="fab-transition"
                ref="nome"
               
                v-model="dados.nome"
                name="nome"
                label="Nome"                
                value
                filled
                
                clearable
                hide-details
              ></v-text-field>
            </v-col>

            <v-col offset="0" cols="12" xl="2" lg="4" md="4" sm="4" xs="6">
             <v-text-field
                transition="fab-transition"
                ref="email"
               
                v-model="dados.email"
                name="email"
                label="Email"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            
            
          </v-row>
           
              <v-row class="mx-auto mt-0 ml-0 pa-5">
             <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                @click="salvar()"
                color="success"
              >
                  <v-icon left>
                 mdi-content-save-outline
                </v-icon>
                 Salvar
              </v-btn>
            </v-col>
          </v-row>
          
      
    </v-card>
      <v-card class="mt-5">
     <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
      
    
    
      <v-data-table
        :headers="headers"
        :items="listaStatus"
        :loading="false"
        :search="search"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
       <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
       
      </v-data-table>  
   
    
     </v-col>
    </v-row>
    </v-card>-->
   
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
  
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    this.buscaGrupos();
    this.buscaStatus();
     this.mudaCampo("codigo");
  },
  data: () => ({
    mostraMsg:false,
    textSnack:"",
    corMsg:"",
    search: "",
    contador:[1,2,3,4,5,6],
    dados:{
      nome:"",
      razao_social:"",
      flg_tipo_pessoa:"",
      cnpj_cpf:"",
      ie:"",
      chk_produtor_rual:false,
      cpf_produtor_rual:"",
      endereco:"",
      cep:"",
      uf:"",
      cidade:"",
      telefone:"",
      celular:"",
      email:""
     
    },
    listaIndent:[{
      valor:'D',
      descricao:"Débito"
    },    
    {
      valor:'C',
      descricao:"Crédito"
    }],
    items: [],    
    itemsBread: [
      {
        text: "Cadastros",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Funcionários",
        disabled: false,
        href: "/dashboard/funcionarios",
      },
    ],
    dialog: false,
    grupos:[],
    
    listaStatus:[],
       headers: [
    
      {
        text: "Nome",
        value: "nome",
      },
    
      { text: "Ações", value: "actions", sortable: false },
    ],
    selDel:"",
    selEdit:"",
    dialogDelete:false,
  }),
  methods:{

     mudarStatus(id,status){

        axios
        .post(
          `/fin/grupo/status`,
          { id: id,chk_status:status, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
        
          if(status){
            this.textSnack = "Ativado com sucesso !";
            this.corMsg = "success";
          }else{
            this.textSnack = "Desativado com sucesso!";
            this.corMsg = "error";
          }
          
        
          this.mostraMsg = true;
          
          //this.grupos = response.data;
        });
    },
     editItem(item){
         this.grupo = item;
     },
     deleteItemConfirm() {
    //  this.listaLancamentos.splice(this.editedIndex, 1);
      this.dialogDelete = false;
      axios
        .post(
          `/fin/grupo/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          this.buscaGrupos();
          this.textSnack = "Exclusão feito com sucesso!";
        
          this.mostraMsg = true;
          this.corMsg = "success";
          //this.grupos = response.data;
        });
    },
    limpaCampos(){
      this.grupo.id_grupo_dep =""
      this.grupo.codigo =""
      this.grupo.descricao = ""
      this.grupo.chk_nao_res_opera = false
      this.grupo.id_fin_status = null
    
    },
    deleteItem(item) {
      
      this.selDel = item.id;
      this.dialogDelete = true;
    },
     mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
     },
     validacao() {

      if (this.grupo.codigo == "") {
        this.textSnack = "Informe o Código";
        this.mudaCampo("codigo");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
      if (this.grupo.descricao == "") {
        this.textSnack = "Preencha a descrição";
        this.mudaCampo("descricao");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       if (this.grupo.id_fin_status == null) {
        this.textSnack = "Selecione um Status";
        this.mudaCampo("status");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       
      return true
     },
   
    
    buscaStatus() {
      axios
        .post(
          `/fin/status/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.listaStatus = response.data;
        });
    },
    salvar(){

        if (!this.validacao()) {
          
          return false;
        }
        axios
        .post(
          `/fin/grupo`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            grupo: this.grupo,
          }
          
        )
        .then((response) => {
        
        
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";
         
          this.mudaCampo("descricao");
          this.limpaCampos();        
         
          this.buscaGrupos();
        })
        .catch((e) => {
          this.loading = false;
          
          if (!Array.isArray(e.response.data)) {
          
            let itens = [];
            for (var prop in e.response.data) {
              itens.push(e.response.data[prop][0]);
            }
         

            this.textSnack = itens.join("");
            this.mostraMsg = true;
            this.corMsg = "error";
          } else {
         
            this.textSnack = e.response.data;

            this.mostraMsg = true;
            this.corMsg = "error";
          }
        });


    }
  }
};
</script>
